import type DataObject from 'o365.modules.DataObject.ts';
import BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';

export default class DataGridControl extends BaseGridControl {
    /** Main data object for the grid */
    dataObject: DataObject;

    dataProps: any;

    constructor(options: {
        id: string
        props: any;
    }) {
        super({ id: options.id, props: null });

        this.dataProps = options.props;
        this.dataObject = options.props.dataObject;
    }

    /** Calls select all method on the data object */
    selectAll() {
        this.dataObject.selectionControl.selectAll(!this.dataObject.selectionControl.allRowsSelected);
    }

    /** Set current index on the data object */
    setCurrentIndex(newIndex: number) {
        this.dataObject.setCurrentIndex(newIndex);
    }

    /** Get constructor for grid extension */
    async getExtension(extension: 'filter' | 'header' | 'navigation') {
        switch (extension) {
            case 'filter':
                return (await import('o365.controls.Grid.DataFilter.ts')).default;
            case 'header':
                return (await import('o365.controls.Grid.extension.DataHeader.ts')).default;
            case 'navigation':
                return (await import('o365.controls.Grid.extensions.DataNavigation.ts')).default;
            default:
                return super.getExtension(extension);
        }
    }

    async initialize(options: {
        container: HTMLElement,
        watchFn: Function,
        columnWatchHandler: Function,
    }) {
        await super.initialize(options);
        this.dataObject.filterObject.updateColumns(this.columns.columns);
    }

    save() {
        this.dataObject.save();
    }

    /** Enables batch records. Called by add new row in grid */
    enableBatchRecords() {
        if (typeof this.dataProps.createNewOverrideFn === 'function') {
            return this.dataProps.createNewOverrideFn();
        }
        this.dataObject.enableBatchData();
    }

    /** Close batch records (new records panel) */
    closeBatchRecords() {
        if (this.dataObject.hasNewRecords) {
            this.dataObject.disableBatchData();
            this.dataObject.mergeBatchData();

            this.gridFocusControl?.clearFocus();
        }
    }
}

export { DataGridControl };